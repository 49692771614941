<template>
    <v-select 
        :multiple="multiple" 
        :filterable="true" 
        :options="options" 
        v-model="selected" 
        :placeholder="placeholder"
        :clearable="false"
    />
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
        value: {
            type: String
        }
    },
    data() {
        return {
            options: ['By Request', 'Alokasi', 'Normalisasi Stock'],
            selected: (this.multiple) ? [] : null
        };
    },
    methods: {},
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        // this.get();
    }
};
</script>